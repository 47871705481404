export const Foes = [
  {
    name: "Goblin",
    level: 1,
    hp: 4,
    maxHP: 4,
    damageDie: 3,
  },
  { name: "Titan Spider", level: 1, hp: 3, maxHP: 3, damageDie: 4 },
  { name: "Skeleton", level: 2, hp: 5, maxHP: 5, damageDie: 4 },
  { name: "Carnivorous Ooze", level: 2, hp: 6, maxHP: 6, damageDie: 3 },
  { name: "Brigand", level: 3, hp: 8, maxHP: 8, damageDie: 6 },
  { name: "Cultist", level: 3, hp: 6, maxHP: 6, damageDie: 8 },
  { name: "Hag", level: 4, hp: 8, maxHP: 8, damageDie: 9 },
  { name: "Prismatic Wizard", level: 4, hp: 6, maxHP: 6, damageDie: 11 },
  { name: "Manticore", level: 5, hp: 12, maxHP: 12, damageDie: 8 },
  {
    name: "Bog Shambler",
    level: 5,
    hp: 14,
    maxHP: 14,
    damageDie: 6,
  },
  {
    name: "Cyclops",
    level: 6,
    hp: 13,
    maxHP: 13,
    damageDie: 10,
  },
  { name: "Dire Crocodile", level: 6, hp: 9, maxHP: 9, damageDie: 8 },
  { name: "Warlock", level: 7, hp: 11, maxHP: 11, damageDie: 15 },
  { name: "Vampire", level: 7, hp: 13, maxHP: 13, damageDie: 13 },
  { name: "Drunken Demigod", level: 8, hp: 18, maxHP: 18, damageDie: 14 },
  {
    name: "Demonoid",
    level: 8,
    hp: 16,
    maxHP: 16,
    damageDie: 16,
  },
  { name: "Wyvern", level: 9, hp: 16, maxHP: 16, damageDie: 16 },
  { name: "Lich", level: 9, hp: 19, maxHP: 19, damageDie: 13 },
  {
    name: "Leviathan",
    level: 100,
    hp: 70,
    maxHP: 70,
    damageDie: 20,
  },
];
